<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'app',
  mounted() {
    // Prevents history traversal from Login page to other pages
    window.onpopstate = () => {
      if (this.$route.path === '/login') {
        window.history.replaceState({ path: '/login' }, '', '/login');
      }
    };
  },
}
</script>

<style>
.help-block {
  color: #f44335;
  font-size: 12px;
  margin-bottom: 20px !important;
}

.tawi-green {
  background-color: #0C8442 !important;
  border-color: #0C8442 !important;
  color: white !important;
}

.tawi-red {
  background-color: #E41E26 !important;
  border-color: #E41E26 !important;
  color: white !important;
}

.tawi-blue {
  background-color: #07529C !important;
  border-color: #07529C !important;
  color: white !important;
}

.tawi-yellow {
  background-color: #FCAE17 !important;
  border-color: #FCAE17 !important;
}

.tawi-secondary {
  background-color: #2E8DCC !important;
  border-color: #2E8DCC !important;
}

.status-pending {
  background-color: #FEEDCC !important;
  color: #FCAE17 !important;
  padding: 5px 10px 5px 10px !important;
  font-weight: 500 !important;
  border-radius: 10px !important;

}

.status-done {
  background-color: #C1D3E6 !important;
  color: #07529C !important;
  padding: 5px 10px 5px 10px !important;
  font-weight: 500 !important;
  border-radius: 10px !important;
}

.icon-color {
  color: #2E8DCC;
}

.yellow-text {
  color: #FCAE17;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #07529C !important;
  font-weight: 700;
}

.custom-control-input:checked~.custom-control-label::before {
  border-color: #FCAE17 !important;
  background-color: #FCAE17 !important;
}

.table thead th {
  font-size: 14px !important;
  font-weight: 700 !important;
}

.table td {
  font-size: 16px !important;
  font-weight: 400 !important;
}

a:not([href]) {
  color: #07529C !important;
  text-decoration: none !important;
}
</style>
