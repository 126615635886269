export default {
	currEnv: 'prod',
	adminAccount: {
		TAWI_SUPPORT: 'support@tawitech.ph'
	},
	emailRegex: /^[\w.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
	passwordRegex: /[A-Za-z0-9<>()\.,\"\'=:;@$%*?!&_\-+|]{8,}/,
	passwordErrorMsg: 'Password must have at least eight (8) characters and should contain at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character',
	nameRegex: /^([ÑñA-Za-z- \.']{1,30})$/,
	positionRegex: /^[ÑñA-Za-z0-9&,\. \'-]{2,60}$/,
	addressRegex: /^(?=.*[ÑñA-Za-z ])[ÑñA-Za-z\d\s(),.#'-]+$/,
	salesContactsRegex: /^[a-zA-Z0-9\s()+-]+$/,
	contactNoRegex: /\(\+63\s?\d{1,2}\)\s?\d{4}\s?\d{4}|^[+]639[0-9]{9}$|^\([0-9]{3}\)[0-9]{3}-[0-9]{4}\/?\([0-9]{3}\)[0-9]{3}-[0-9]{4}/,
	inquiryStatus: [
		{
			value: null,
			text: ' - Please select - '
		},
		{
			value: 'PENDING',
			text: 'Pending'
		},
		{
			value: 'DONE',
			text: 'Done'
		},
	],
	genderOptions: [
		{ text: "Male", value: "Male" },
		{ text: "Female", value: "Female" }
	],
}